

import { Vue, Component } from 'vue-property-decorator'
import { DataList } from '@/types/assestsManage'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'

@Component({
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class DeviceAdd extends Vue {
  private assestsInfo: DataList = {
    // 名称
    name: '',
    // 品牌
    brand: '',
    // 型号
    model: '',
    // 位置
    position: '',
    // 保管人
    keepUser: '',
    // 备注
    remark: '',
    resourceList: []
  }

  private rules = {
    name: [
      { required: true, message: '请输入资产名称', trigger: 'change' }
    ],
    brand: [
      { required: true, message: '请输入品牌', trigger: 'change' }
    ],
    position: [
      { required: true, message: '请输入所在位置', trigger: 'change' }
    ]
  }

  onSuccess (file: FileInfo) {
    this.assestsInfo.resourceList.push(file)
  }

  deleteImg (index: number) {
    this.assestsInfo.resourceList.splice(index, 1)
  }

  submit (formName: string) {
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid) {
        this.$axios.post(this.$apis.assestsManage.insertAsset, this.assestsInfo).then(() => {
          this.$message.success('添加成功！')
          this.$router.back()
        })
      }
    })
  }
}
